<script setup lang='ts'>
import type { FollowerPlacement } from 'vueuc'
import { isClient } from '@qcwp/utils'
import { usePoster } from '~~/src/common/function/poster'
import type { LivePosterOptions, SharePosterType } from '~~/src/common/function/poster'
import { useShareInject } from '~~/src/common/function/shareInject'

const props = withDefaults(defineProps<{
  placement?: FollowerPlacement
  posterType?: SharePosterType
  showText?: boolean
}>(), {
  placement: 'right',
  posterType: 'article',
  showText: true,
})

const bus = useNuxtApp().$bus as any

const { shareBase, option, copyHandler } = useShareInject(props.posterType)
const { base64, showPoster, createPoster, preCreatePoster } = usePoster(props.posterType)

const { stop: stopHandler, weiboShareUrl, qzoneShareUrl, weixinQrcode } = useShare(shareBase.value || {})

function liveEndHandler(data: boolean) {
  if (data)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
    option.value.liveIsEnd = true
}
function initHandler() {
  bus.off('LIVE_END', liveEndHandler)
  if (unref(option) && isClient && (option.value as LivePosterOptions).liveStartTime)
    bus.on('LIVE_END', liveEndHandler)
}
let stop = watch(option, () => {
  if (!isClient || !option.value)
    return
  initHandler()
  base64.value = ''
  // remove auto create poster 2023/10/11
  // preCreatePoster(unref(option))
}, { deep: true, immediate: true })

onBeforeUnmount(() => {
  stopHandler?.()
  stop()
  bus.off('LIVE_END', liveEndHandler)
  stop = null!
})
</script>

<template>
  <n-popover trigger="hover" display-directive="show" :show-arrow="false" :placement="placement" raw>
    <template #trigger>
      <slot>
        <button class="affix-item">
          <i class="i-icon-park-solid:share-two" />
        </button>
      </slot>
    </template>
    <div class="bg-white" :class="[showText ? 'w-30  py-2' : 'flex rounded-md overflow-hidden']">
      <n-popover
        trigger="hover" display-directive="show" :show-arrow="false" :placement="showText ? 'right-start' : 'bottom'" raw
      >
        <template #trigger>
          <div class="share-item" title="微信">
            <i class="i-custom-weixin?bg mr-1 text-4.5" />
            <span v-if="showText">微信</span>
          </div>
        </template>
        <div pb-3 bg-white>
          <img w-30 h-30 :src="weixinQrcode">
          <div px-2 text-center>
            微信扫码分享
          </div>
        </div>
      </n-popover>
      <NuxtLink :to="weiboShareUrl" target="_blank" class="share-item" title="微博">
        <i class="i-custom-weibo?bg mr-1  text-4.5" />
        <span v-if="showText">微博</span>
      </NuxtLink>
      <NuxtLink :to="qzoneShareUrl" target="_blank" class="share-item" title="QQ">
        <i class="i-custom-qq?bg mr-1  text-4.5" />
        <span v-if="showText">QQ</span>
      </NuxtLink>
      <div class="share-item" title="生成海报" @click="createPoster({ ...option })">
        <i class="i-carbon:image text-primary mr-1 text-4.5" />
        <span v-if="showText">生成海报</span>
      </div>
      <div class="share-item" title="复制链接" @click="copyHandler">
        <i class=" i-akar-icons:link-chain mr-1 text-4.5" />
        <span v-if="showText">复制链接</span>
      </div>
      <n-popover
        trigger="hover" display-directive="show" :show-arrow="false" :placement="showText ? 'right-start' : 'bottom'" raw
      >
        <template #trigger>
          <div class="share-item" title="手机访问">
            <i class="i-ic:twotone-phone-iphone mr-1 text-4.5" />
            <span v-if="showText">手机访问</span>
          </div>
        </template>
        <div bg-white>
          <img w-30 h-30 :src="weixinQrcode">
        </div>
      </n-popover>
    </div>
  </n-popover>
  <utils-show-poster v-model="showPoster" :base64="base64" />
</template>

<style lang='scss' scoped>
@import "./styles/pc.scss";

.share {
  &-item {
    --uno: flex items-center text-title-400 hover:bg-gray-100 px-3 py-2 cursor-pointer;
  }
}

:deep(.n-popover) {
  border-radius: 12px;
}
</style>
