import type { PosterContext, PosterJson } from '@qcwp/common'
import { Poster, PosterType, generateGaussBlurImage, imageTransform } from '@qcwp/common'
import { isArray, isClient, isString, numFormat, timeFormat } from '@qcwp/utils'

import { downloadImage } from '../proxyImg'
import type { LivePosterOptions } from './types'

export async function useLivePoster(options: LivePosterOptions) {
  if (!isClient)
    return
  let color = '#0f172a'
  const BG_POSTER = options.cover || useAssetImages('2')
  const qrcode = await generateCode(options.href, { color: { dark: `${color}ff`, light: '#ffffffff' } }) || ''

  function posterContentHandle(context: PosterContext): [number, PosterJson[]] {
    const { width, height } = context
    const offsetX = 0
    const offsetY = 10
    const contentWidth = context.width - offsetX * 2
    const contentHeight = height - offsetY * 2
    const contentJson: PosterJson = {
      type: PosterType.rect,
      x: width / 2 - contentWidth / 2,
      y: offsetY,
      width: contentWidth,
      height: contentHeight,
      bgColor: '#000000',
    }

    const isSingleMedia = isString(options.mediaLogo) || (isArray(options.mediaLogo) && (options.mediaLogo as string[]).length === 1)
    const paddingTop = 20
    const basePadding = 15
    let needHeight = paddingTop

    const [singleMediaHeight, singleMediaJson] = isSingleMedia ? singleMediaHandle(context, options, { color }) : [0, []]
    if (singleMediaHeight) {
      const singleMediaPadding = 10
      needHeight += singleMediaHeight + singleMediaPadding
      singleMediaJson.forEach((json) => {
        json.y += paddingTop
      })
    }
    // tips
    const [tipsHeight, tipsJson] = tipsHandle(context, options, { color })
    const tipsPadding = 10
    tipsJson.y += needHeight + tipsPadding
    needHeight += tipsHeight + tipsPadding * 2

    // cover
    const [coverHeight, coverJson] = coverHandle(context, options)
    coverJson.y += needHeight
    needHeight += coverHeight

    // title
    const [titleHeight, titleJson] = titleHandle(context, options, { color })
    titleJson.y += needHeight + basePadding
    needHeight += titleHeight + basePadding

    // live time
    const [timeHeight, timeJson] = timeHandle(context, options, { qrcode, color })
    const timePadding = 20
    timeJson.forEach((json) => {
      json.y += needHeight + timePadding
    })
    needHeight += timeHeight + timePadding * 2

    const [multiMediaHeight, multiMediaJson] = isSingleMedia ? [0, []] : multiMediaHandle(context, options, { color })
    if (multiMediaHeight) {
      const multiMediaPadding = 30
      multiMediaJson.forEach((json) => {
        json.y += needHeight + multiMediaPadding
      })
      needHeight += multiMediaHeight + multiMediaPadding
    }

    // logo
    const logoJson = posterLogoHandle(context, contentJson)
    for (const json of logoJson)
      json.y = needHeight + logoJson[0].height + basePadding * 2

    needHeight += logoJson[0].height + basePadding * 2 + 5

    return [
      needHeight,
      [
        ...singleMediaJson,
        tipsJson,
        coverJson,
        titleJson,
        ...timeJson,
        ...multiMediaJson,
        ...logoJson,
      ],
    ]
  }

  function posterEndContentHandle(context: PosterContext): [number, PosterJson[]] {
    const { width, height } = context
    const offsetX = 0
    const offsetY = 10
    const contentWidth = context.width - offsetX * 2
    const contentHeight = height - offsetY * 2
    const contentJson: PosterJson = {
      type: PosterType.rect,
      x: width / 2 - contentWidth / 2,
      y: offsetY,
      width: contentWidth,
      height: contentHeight,
      bgColor: '#000000',
    }

    const isSingleMedia = isString(options.mediaLogo) || (isArray(options.mediaLogo) && (options.mediaLogo as string[]).length === 1)
    const paddingTop = 20
    const basePadding = 15
    let needHeight = paddingTop

    // cover
    const [coverHeight, coverJson] = coverHandle(context, options)
    coverJson.y += needHeight
    needHeight += coverHeight

    // title
    const [titleHeight, titleJson] = titleHandle(context, options, { color })
    titleJson.y += needHeight + basePadding
    needHeight += titleHeight + basePadding

    // tips
    const [tipsHeight, tipsJson] = tipsHandle(context, options, { color })
    const tipsPadding = 25
    tipsJson.y += needHeight + tipsPadding
    needHeight += tipsHeight + tipsPadding * 2

    // live count
    const [countHeight, countJson] = endLiveCountHandle(context, options, { color })
    const countPadding = 15
    countJson.forEach((json) => {
      json.y += needHeight + countPadding
    })
    needHeight += countHeight

    // qrcode
    const [qrcodeHeight, qrcodeJson] = endQrcodeHandle(context, options, { qrcode, color, isSingleMedia })
    const qrcodePadding = 20
    qrcodeJson.forEach((json) => {
      json.y += needHeight + qrcodePadding
    })
    needHeight += qrcodeHeight + qrcodePadding

    const [multiMediaHeight, multiMediaJson] = isSingleMedia ? [0, []] : multiMediaHandle(context, options, { color })
    if (multiMediaHeight) {
      const multiMediaPadding = 25
      multiMediaJson.forEach((json) => {
        json.y += needHeight + multiMediaPadding
      })
      needHeight += multiMediaHeight + multiMediaPadding
    }

    // logo
    const logoJson = posterLogoHandle(context, contentJson)
    for (const json of logoJson)
      json.y = needHeight + logoJson[0].height + basePadding * 2

    needHeight += logoJson[0].height + basePadding * 2 + 5

    return [
      needHeight,
      [
        // ...singleMediaJson,
        coverJson,
        titleJson,
        tipsJson,
        ...countJson,
        ...qrcodeJson,
        ...multiMediaJson,
        ...logoJson,
      ],
    ]
  }

  /**
   * logo
   */
  function posterLogoHandle(context: PosterContext, contentJson: PosterJson): PosterJson[] {
    const offsetY = contentJson.y + contentJson.height + 5
    const offsetX = contentJson.x

    const text = ''
    const fontSize = 5
    const font = {
      fontSize,
      fontWeight: 'normal',
      lineHeight: 1,
      fontFamily: '宋体',
    } as const
    const letterSpacing = 1
    const tipsContentWidth = context.getTextWidth(text, context.font(font), letterSpacing)

    const startX = offsetX + contentJson.width - tipsContentWidth - 5
    // logo tips
    const logoTipsJson: PosterJson = {
      type: PosterType.text,
      text,
      x: startX,
      y: offsetY,
      letterSpacing,
      width: tipsContentWidth,
      height: fontSize,
      ...font,
      color,
    }

    return [
      logoTipsJson,
    ]
  }

  async function createPoster() {
    const poster = new Poster({ scale: 3, proxy: downloadImage })

    const liveIsEnd = options.liveIsEnd
    const bgResult = await poster.generateDrawAsync(async (ctx) => {
      const [c, result] = await backgroundHandle(ctx, { BG_POSTER })
      color = c
      return result
    })

    const [needHeight, posterContent] = poster.generateDraw(ctx => liveIsEnd
      ? posterEndContentHandle(ctx) as any
      : posterContentHandle(ctx),
    )
    const contentJson: PosterJson[] = [
      posterContent,
    ].flat()
    poster.resize({ height: needHeight })
    const result = poster.generateDraw((ctx) => {
      bgResult.height = ctx.height
      return bgResult
    })
    contentJson.unshift(result)
    // 内容区域
    return await poster.create(contentJson)
  }

  return {
    createPoster,
  }
}

async function backgroundHandle(context: PosterContext, config: { BG_POSTER: string }): Promise<[string, PosterJson]> {
  const { BG_POSTER } = config
  // TODO: 站内图可直接缩略图+模糊做直播海报背景
  // https://qcts.cdn.bcebos.com/qcwp/202310/12/1712343530334703616.jpg?x-bce-process=image/resize,m_fill,w_800,h_800/blur,r_50,s_50

  const { width, height } = context
  const { img, color: rgb } = await generateGaussBlurImage({
    width,
    height,
    radius: 50,
    sigma: 50,
    src: imageTransform(BG_POSTER, { width, height, format: 'webp' }),
  })
  const [r, g, b] = rgb
  // console.log(rgb, r * 0.299 + g * 0.587 + b * 0.114)
  const color = (r * 0.213 + g * 0.715 + b * 0.072) > 255 / 2 ? '#0f172a' : '#f8fafc'
  return [color,
    {
      type: PosterType.image,
      src: img,
      x: 0,
      y: 0,
      width,
      height,
      objectFit: 'cover',
    },
  ]
}

function tipsHandle(context: PosterContext, options: LivePosterOptions, config: {
  color: string
}): [number, PosterJson] {
  const { color } = config
  const text = options.liveIsEnd ? '直播已结束' : '邀您一起看直播'
  const font = {
    fontSize: options.liveIsEnd ? 14 : 20,
    fontWeight: options.liveIsEnd ? 'normal' : 'bolder',
    lineHeight: 1.3,
  } as const
  const letterSpacing = 3
  const textWidth = context.getTextWidth(text, context.font(font), letterSpacing)

  const startX = (context.width - textWidth) / 2
  const startY = 0
  const json: PosterJson = {
    type: PosterType.text,
    text,
    x: startX,
    y: startY,
    letterSpacing,
    width: textWidth,
    textAlign: 'left',
    textBaseline: 'top',
    height: font.fontSize * font.lineHeight,
    color,
    ...font,
  }
  return [json.height, json]
}
function coverHandle(context: PosterContext, options: LivePosterOptions): [number, PosterJson] {
  const padding = 0
  const width = context.width - padding
  const startX = (context.width - width) / 2
  const startY = 0
  const coverJson: PosterJson = {
    type: PosterType.image,
    src: options.cover,
    x: startX,
    y: startY,
    width,
    height: width * 0.5625,
    objectFit: 'cover',
  }
  return [coverJson.height, coverJson]
}

function titleHandle(context: PosterContext, options: LivePosterOptions, config: {
  color: string
}): [number, PosterJson] {
  const { color } = config
  const text = `${options.title}`

  const padding = 40
  const font = {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 1.3,
  } as const
  const width = context.width - padding
  let lines = context.getTextLineCount(width, text, context.font(font))
  lines > 2 && (lines = 2)

  const startX = (context.width - width) / 2
  const startY = 0
  const titleJson: PosterJson = {
    type: PosterType.textEllipsis,
    text,
    x: startX + width / 2,
    y: startY,
    textBaseline: 'top',
    width,
    textAlign: 'center',
    height: font.fontSize * font.lineHeight * lines,
    // renderType: 'strokeAndFill',
    // strokeColor: color === '#f8fafc' ? '#0f172a' : '#f8fafc',
    ...font,
    color,
  }
  return [titleJson.height, titleJson]
}

function timeHandle(
  context: PosterContext,
  options: LivePosterOptions,
  config: {
    qrcode: string
    color: string
  },
): [number, PosterJson[]] {
  const { qrcode, color } = config
  const text = '直播开始时间'
  const day = options.liveStartTime ? timeFormat(options.liveStartTime, 'MM/DD') : ''
  const startTime = options.liveStartTime ? timeFormat(options.liveStartTime, 'HH:mm') : ''
  const timeText = `${startTime}`

  const font = {
    fontSize: 11,
    fontWeight: 'bold',
    lineHeight: 1.3,
  } as const
  const dayFont = {
    fontSize: 9,
    fontWeight: 'bold',
    lineHeight: 1.3,
  } as const
  const letterSpacing = 3
  const textWidth = context.getTextWidth(text, context.font(font), letterSpacing)
  const dayTextWidth = context.getTextWidth(day, context.font({ ...dayFont }))

  const qrcodeWidth = 55
  const startX = (context.width - textWidth - qrcodeWidth) / 2
  const startY = 0

  const qrcodeJson: PosterJson = {
    type: PosterType.image,
    src: qrcode,
    x: startX,
    y: startY,
    width: qrcodeWidth,
    height: qrcodeWidth,
  }

  const json: PosterJson = {
    type: PosterType.text,
    text,
    x: qrcodeJson.x + qrcodeJson.width + 10,
    y: startY + (font.fontSize * font.lineHeight) / 2,
    letterSpacing,
    width: textWidth,
    height: font.fontSize * font.lineHeight,
    textBaseline: 'top',
    color,
    // renderType: 'strokeAndFill',
    // strokeColor: color === '#f8fafc' ? '#0f172a' : '#f8fafc',
    ...font,
  }
  const dayJson: PosterJson = {
    type: PosterType.text,
    text: day,
    x: json.x,
    y: json.y + json.height + 2,
    width: dayTextWidth,
    height: dayFont.fontSize * dayFont.lineHeight,
    color,
    textBaseline: 'top',
    ...dayFont,
  }
  const time: PosterJson = {
    type: PosterType.text,
    text: timeText,
    x: json.x,
    y: dayJson.height + dayJson.y + 2,
    width: textWidth,
    height: font.fontSize * font.lineHeight,
    color,
    textBaseline: 'top',
    ...font,
    fontSize: 15,
  }
  return [time.height + dayJson.height + json.height, [qrcodeJson, json, dayJson, time]]
}

function singleMediaHandle(context: PosterContext, options: LivePosterOptions, config: { color: string }): [number, PosterJson[]] {
  if (isArray(options.mediaLogo))
    return [0, []]

  const { mediaLogo } = options
  const { color } = config
  const selfOffsetX = 12
  const text = options.mediaName
  const font = {
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 1,
  } as const

  const mediaLogoSize = 25
  const margin = 5
  const startX = selfOffsetX
  const startY = 0
  const mediaLogoJson: PosterJson[] = []
  if (options.mediaLogo) {
    mediaLogoJson.push({
      type: PosterType.rect,
      x: startX,
      y: startY,
      width: mediaLogoSize,
      height: mediaLogoSize,
      boxRadius: mediaLogoSize / 2,
      bgColor: '#ffffff',
    }, {
      type: PosterType.image,
      src: mediaLogo,
      objectFit: 'cover',
      x: startX,
      y: startY,
      width: mediaLogoSize,
      height: mediaLogoSize,
      boxRadius: mediaLogoSize / 2,
    })
  }

  const mediaNameWidth = context.getTextWidth(text, context.font(font))
  const nameStartX = startX + (mediaLogo ? mediaLogoSize : 0) + margin
  const nameStartY = startY + (mediaLogo ? mediaLogoSize : 0) / 2 + 2
  const mediaJson: PosterJson = {
    type: PosterType.text,
    text,
    x: nameStartX,
    y: nameStartY,
    textBaseline: 'middle',
    width: mediaNameWidth,
    height: font.fontSize * font.lineHeight,
    ...font,
    color,
  }
  return [mediaLogoSize, [mediaLogoJson, mediaJson].flat()]
}
function multiMediaHandle(
  context: PosterContext,
  options: LivePosterOptions,
  config: {
    color: string
  },
): [number, PosterJson[]] {
  const { color } = config
  const mediaLogo = options.mediaLogo as unknown as string[]

  if (!isArray(mediaLogo))
    return [0, []]

  const json: PosterJson[] = []
  let needHeight = 0

  const titleText = '参与直播媒体'
  const marginBottom = 15
  const font = {
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 1,
  } as const

  const titleTextWidth = context.getTextWidth(titleText, context.font(font))
  const titleJson: PosterJson = {
    type: PosterType.text,
    text: titleText,
    x: (context.width - titleTextWidth) / 2,
    y: needHeight,
    width: titleTextWidth,
    height: font.fontSize * font.lineHeight,
    color,
    ...font,
    textBaseline: 'top',
  }
  json.push(titleJson)
  needHeight += titleJson.height

  /** ******************************************** */
  const { dpi } = context
  const padding = 50
  const lineGap = 30
  const width = (context.width - lineGap - titleTextWidth - padding) / 2
  const lineHeight = 1
  const startY = titleJson.y + titleJson.height / 2 - lineHeight / 2
  const firstStartX = titleJson.x - lineGap / 2 - width
  const secondStartX = titleJson.x + titleTextWidth + lineGap / 2
  const firstG = context.canvasContext.createLinearGradient(firstStartX * dpi, startY * dpi, (firstStartX + width) * dpi, startY * dpi)
  firstG.addColorStop(0, `${color}00`)
  firstG.addColorStop(1, `${color}ff`)

  const secondG = context.canvasContext.createLinearGradient(secondStartX * dpi, startY * dpi, (secondStartX + width) * dpi, startY * dpi)
  secondG.addColorStop(0, `${color}ff`)
  secondG.addColorStop(1, `${color}00`)
  const firstLineJson: PosterJson = {
    type: PosterType.rect,
    x: firstStartX,
    y: startY,
    width,
    height: lineHeight,
    bgColor: firstG,
  }
  const secondLineJson: PosterJson = {
    type: PosterType.rect,
    x: secondStartX,
    y: startY,
    width,
    height: lineHeight,
    bgColor: secondG,
  }
  json.push(firstLineJson, secondLineJson)
  /** ******************************************** */

  const tipsText = '* 排名不分先后'
  const tipsFont = {
    fontSize: 6,
    lineHeight: 1,
  } as const
  const tipsTextWidth = context.getTextWidth(tipsText, context.font(tipsFont))
  const tipsJson: PosterJson = {
    type: PosterType.text,
    text: tipsText,
    x: (context.width - tipsTextWidth) / 2,
    y: needHeight + tipsFont.fontSize,
    width: tipsTextWidth,
    height: tipsFont.fontSize * tipsFont.lineHeight,
    ...tipsFont,
    color,
    textBaseline: 'top',
  }
  needHeight += tipsJson.height + tipsFont.fontSize + marginBottom
  json.push(tipsJson)

  const mediaSize = 45
  const mediaGap = 8
  const boxRadius = 10
  function mediaJsonHandle(src: string, startX: number, startY: number): PosterJson[] {
    return [{
      type: PosterType.rect,
      x: startX,
      y: startY,
      width: mediaSize,
      height: mediaSize,
      bgColor: '#ffffff',
      boxRadius,
    }, {
      type: PosterType.image,
      x: startX,
      y: startY,
      width: mediaSize,
      height: mediaSize,
      src,
      boxRadius,
      objectFit: 'cover',
    },
    ]
  }

  const medias = []
  for (let i = 0, len = mediaLogo.length; i < len; i += 4)
    medias.push(mediaLogo.slice(i, i + 4))

  medias.forEach((media) => {
    let offsetX = 0
    const offsetY = needHeight
    if (media.length < 4)
      offsetX = (context.width - (mediaSize * media.length + mediaGap * (media.length - 1))) / 2
    else
      offsetX = (context.width - (mediaSize * 4 + mediaGap * 3)) / 2

    for (const i in media) {
      const mediaJson = mediaJsonHandle(media[i], offsetX, offsetY)
      json.push(...mediaJson)
      offsetX += mediaSize + mediaGap
    }

    needHeight += mediaSize + mediaGap
  })
  needHeight -= mediaGap
  return [needHeight, json]
}

function endQrcodeHandle(
  context: PosterContext,
  options: LivePosterOptions,
  config: {
    qrcode: string
    color: string
    isSingleMedia: boolean
  },
): [number, PosterJson[]] {
  const { qrcode, color } = config
  const { mediaName, mediaLogo } = options
  const font = {
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 1,
  } as const
  const mediaNameWidth = context.getTextWidth(mediaName, context.font(font))
  const gap = 10
  const qrcodeWidth = 50
  const mediaLogoSize = mediaLogo ? 25 : 0
  const startX = (context.width - mediaLogoSize - mediaNameWidth - qrcodeWidth - gap - 5) / 2
  const startY = 0

  const mediaLogoJson: PosterJson[] = []
  if (mediaLogo) {
    mediaLogoJson.push({
      type: PosterType.rect,
      x: startX,
      y: startY + (qrcodeWidth - mediaLogoSize) / 2,
      width: mediaLogoSize,
      height: mediaLogoSize,
      boxRadius: mediaLogoSize / 2,
      bgColor: '#ffffff',
    },
    {
      type: PosterType.image,
      src: mediaLogo,
      objectFit: 'cover',
      x: startX,
      y: startY + (qrcodeWidth - mediaLogoSize) / 2,
      width: mediaLogoSize,
      height: mediaLogoSize,
      boxRadius: mediaLogoSize / 2,
    })
  }
  const mediaNameJson: PosterJson = {
    type: PosterType.text,
    text: mediaName,
    x: startX + mediaLogoSize + 5,
    y: startY + (qrcodeWidth - font.fontSize * font.lineHeight) / 2 + 2,
    width: mediaNameWidth,
    height: font.fontSize * font.lineHeight,
    color,
    ...font,
    textBaseline: 'top',
  }

  const qrcodeStartX = config.isSingleMedia ? (startX + mediaLogoSize + mediaNameWidth + gap + 5) : (context.width - qrcodeWidth) / 2
  const qrcodeJson: PosterJson = {
    type: PosterType.image,
    src: qrcode,
    x: qrcodeStartX,
    y: startY,
    width: qrcodeWidth,
    height: qrcodeWidth,
  }
  const json = config.isSingleMedia ? [...mediaLogoJson, mediaNameJson, qrcodeJson] : [qrcodeJson]
  return [qrcodeJson.height, json]
}
function endLiveCountHandle(
  context: PosterContext,
  options: LivePosterOptions,
  config: {
    color: string
  },
): [number, PosterJson[]] {
  const { color } = config
  const fontLarge = {
    fontSize: 19,
    fontWeight: 'bold',
    lineHeight: 1,
  } as const
  const fontSmall = {
    fontSize: 10,
    lineHeight: 1,
  } as const
  const lookText = numFormat(Number(options.liveLookTotal) || 0)!.toString()
  const lookTips = ' 人观看过'
  const likeText = numFormat(Number(options.liveLikeTotal) || 0)!.toString()
  const likeTips = ' 次点赞'

  const lookTextWidth = context.getTextWidth(lookText, context.font(fontLarge))
  const lookTipsWidth = context.getTextWidth(lookTips, context.font(fontSmall))
  const likeTextWidth = context.getTextWidth(likeText, context.font(fontLarge))
  const likeTipsWidth = context.getTextWidth(likeTips, context.font(fontSmall))

  const gap = 30
  const startX = (context.width - lookTextWidth - lookTipsWidth - likeTextWidth - likeTipsWidth - gap) / 2
  const startY = 0
  const lookJson: PosterJson = {
    type: PosterType.text,
    text: lookText,
    x: startX,
    y: startY,
    width: lookTextWidth,
    height: fontLarge.fontSize * fontLarge.lineHeight,
    color,
    ...fontLarge,
  }
  const lookTipsJson: PosterJson = {
    type: PosterType.text,
    text: lookTips,
    x: lookJson.x + lookJson.width,
    y: startY,
    width: lookTipsWidth,
    height: fontSmall.fontSize * fontSmall.lineHeight,
    color,
    ...fontSmall,
  }
  const likeJson: PosterJson = {
    type: PosterType.text,
    text: likeText,
    x: lookTipsJson.x + lookTipsJson.width + gap,
    y: startY,
    width: likeTextWidth,
    height: fontLarge.fontSize * fontLarge.lineHeight,
    color,
    ...fontLarge,
  }
  const likeTipsJson: PosterJson = {
    type: PosterType.text,
    text: likeTips,
    x: likeJson.x + likeJson.width,
    y: startY,
    width: likeTipsWidth,
    height: fontSmall.fontSize * fontSmall.lineHeight,
    color,
    ...fontSmall,
  }
  return [fontLarge.fontSize * fontLarge.lineHeight, [lookJson, lookTipsJson, likeJson, likeTipsJson]]
}
