import { USER_TYPE, imageTransform } from '@qcwp/common'
import copy from 'clipboard-copy'
import { isArray, timeStamp } from '@qcwp/utils'
import { SYMBOL_CONTENT_DETAIL, SYMBOL_SHARE_BASE, SYMBOL_SHARE_MEDIA } from '~~/src/common/const/symbol'
import type { ShareBase, ShareMedia } from '~~/src/composables/useShare'
import type { ArticleDetail } from '~~/src/logic/article/detail'
import type { SharePosterOptions, SharePosterType } from '~~/src/common/function/poster'
import type { LiveMedia } from '~~/src/server/modules/article/type'

export function useShareInject<Type extends SharePosterType>(type: Type) {
  const imageFormat = { width: 200, height: 200, format: 'webp' } as const
  const imageFormat2 = { width: 480, height: 270, format: 'webp' } as const

  const shareBase = inject(unref(SYMBOL_SHARE_BASE()), {} as Ref<ShareBase>)
  const detail = inject(unref(SYMBOL_CONTENT_DETAIL()), {} as Ref<ArticleDetail>)
  const media = inject(unref(SYMBOL_SHARE_MEDIA()), {} as ComputedRef<ShareMedia>)
  const option = computed(() => {
    if (['article', 'video'].includes(type)) {
      return {
        href: unref(shareBase)?.url,
        title: unref(shareBase)?.title,
        mediaName: unref(media)?.mediaName || '',
        mediaLogo: imageTransform(unref(media)?.mediaAvatar, imageFormat),
        mediaType: unref(media)?.mediaType || USER_TYPE.INDIVIDUAL,
        authorName: unref(detail).authorName || '',
        sysUserName: unref(detail).sysUserName || '',
        cover: imageTransform(unref(shareBase)?.images?.[0], imageFormat2),
        lookCount: unref(shareBase)?.lookCount || 0,
      } as SharePosterOptions[Type]
    }
    else {
      const m: LiveMedia = (isArray(media.value) && media.value.length === 1)
        ? media.value[0]
        : (media.value || {})
      // console.log('mmmm', m)
      return {
        href: unref(shareBase)?.url,
        title: unref(shareBase)?.title,
        mediaName: isArray(m)
          ? m.map(v => v.mediaName)
          : (m?.mediaName || ''),
        mediaLogo: isArray(m)
          ? m.map(v => imageTransform(v.mediaAvatar, imageFormat))
          : imageTransform(m?.mediaAvatar, imageFormat),
        mediaType: m.mediaType || USER_TYPE.INDIVIDUAL,
        authorName: unref(detail).authorName || '',
        sysUserName: unref(detail).sysUserName || '',
        cover: imageTransform(unref(shareBase)?.images?.[0], imageFormat2),
        liveStartTime: unref(shareBase)?.liveStartTime,
        liveEndTime: unref(shareBase)?.liveEndTime,
        liveIsEnd: timeStamp(unref(shareBase)?.liveEndTime) < timeStamp(),
        liveLookTotal: unref(shareBase)?.liveLookTotal,
        liveLikeTotal: unref(shareBase)?.liveLikeTotal,
      } as SharePosterOptions[Type]
    }
  })

  function copyHandler() {
    copy(`${unref(shareBase)?.title || unref(detail)?.title || ''}\n${window.location.href}`).then(() => {
      vantToast({ message: '复制成功' })
    }).catch(() => {
      vantToast({ message: '复制失败' })
    })
  }
  return {
    shareBase,
    detail,
    media,
    option,
    copyHandler,
  }
}
