<script setup lang='ts'>
import { useClipboard } from '@vueuse/core'
import { isClient } from '@qcwp/utils'
const props = withDefaults(defineProps<{
  content: string
}>(), {
  content: '没有内容',
})
const { copy, copied, isSupported } = useClipboard({ source: props.content })

let stop = watch(copied, () => {
  if (isClient) {
    if (copied)
      vantToast({ message: '复制成功' })
  }
})
onUnmounted(() => {
  stop()
  stop = null!
})
</script>

<template>
  <span v-if="isSupported" class="cursor-pointer i-fluent:copy-16-regular" @click.stop="copy(props.content)" />
</template>
