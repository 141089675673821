<script setup lang='ts'>
import { isString } from '@qcwp/utils'
import type { USER_TYPE } from '@qcwp/common'
import { USER_TYPE_TEXT, imageTransform } from '@qcwp/common'
import { pxToRem } from '~~/src/common/theme/naive'
const props = withDefaults(defineProps<{
  size?: number | 'small' | 'medium' | 'large'
  src?: string
  type?: USER_TYPE
  mediaGrade?: string
  name?: string
  isPoster?: boolean

}>(), {
  size: 'small',
  name: '',
  isPoster: false,
})

const preSetSize = {
  small: 12,
  medium: 14,
  large: 18,
}

const iconStyle = computed(() => {
  const size = isString(props.size) ? preSetSize[props.size] : Math.floor(props.size / 3)
  return {
    width: `${pxToRem(Math.max(size, 10))}rem`,
    height: `${pxToRem(Math.max(size, 10))}rem`,
    fontSize: `${pxToRem(Math.max(Math.floor(size / 1.8), 7))}rem`,
  }
})
const avatarLoadError = ref(false)
// 用于解决n-avatar在切换路由时，文本位置不正确的问题
const loadState = RENDER_LOAD_STATE()
</script>

<template>
  <span
    class="text-0 relative inline-block border-1 border-gray-1 rounded-full"
    :title="`${props.name}${props.type ? ` - ${USER_TYPE_TEXT[props.type]}` : ''}`"
  >
    <n-avatar
      v-if="!avatarLoadError && props.src"
      round
      crossorigin="use-credentials"
      object-fit="contain"
      :size="props.size" :src="imageTransform(props.src, { format: 'webp' }) || '/noAvatar.jpg'"
      color="#F3F4F6"
      :img-props="{ alt: props.name }"
      @error="avatarLoadError = true"
    />
    <n-avatar v-else-if="loadState === PAGE_STATE.LOADED || true" round :size="props.size" color="#2080f0">
      {{ props.name }}
    </n-avatar>
    <span
      v-if="props.type && ['10', '20'].includes(String(props.mediaGrade))"
      :style="iconStyle"
      class="absolute bottom-0 -right-1 rounded-full z-2"
      :class="{
        'grid bg-[#2080f0] place-items-center': '10' === String(props.mediaGrade),
        'grid bg-[#ffcc32] place-items-center': '20' === String(props.mediaGrade),
      }"
    >
      <i i-la:vimeo-v text-white />
    </span>
  </span>
</template>
