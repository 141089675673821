<script setup lang='ts'>
import { isClient } from '@qcwp/utils'

const weixinQrcode = ref('')
const route = useRoute()
if (isClient) {
  const stop = watch(() => route.fullPath, async () => {
    if (route.fullPath)
      weixinQrcode.value = await generateCode(window.location.href) || ''
  }, { immediate: true })
  onBeforeUnmount(stop)
}
</script>

<template>
  <div>
    <n-popover trigger="hover" raw display-directive="show">
      <template #trigger>
        <div class="flex flex-col items-center cursor-pointer h-full text-gray-400">
          <i class="i-ant-design:mobile-outlined text-4 mb-0.5" />
          <span text-3>手机版</span>
        </div>
      </template>
      <div class="pt-3 bg-white flex flex-col gap-0.5 items-center">
        <span text-3>扫码访问手机版</span>
        <img class="w-35 h-35" :src="weixinQrcode" alt="手机端二维码">
      </div>
    </n-popover>
  </div>
</template>

<style lang='scss' scoped>

</style>
