<script setup lang='ts'>
import { MEDIA_MD } from '@qcwp/common'

const props = withDefaults(defineProps<{
  modelValue?: boolean
  base64?: string
  tips?: string
  canvas?: HTMLCanvasElement
}>(), {
  modelValue: false,
  tips: '',
})
const emits = defineEmits({
  'update:modelValue': (show: boolean) => typeof show === 'boolean',
})
function updateShowHandle(show: boolean) {
  emits('update:modelValue', show)
}
onDeactivated(() => {
  updateShowHandle(false)
})

// TODO: 本想直接展示canvas,然后通过用户长按直接保存成图片
// 但是在移动端长按canvas无保存图片选项
// const elRef = ref()
// watch(() => [elRef.value, props.canvas], () => {
//   if (elRef.value && props.canvas && !props.base64) {
//     props.canvas.setAttribute('class', 'h-90vh')
//     elRef.value.appendChild(props.canvas)
//   }
// })
</script>

<template>
  <n-modal :show="modelValue" :style="{ '--n-box-shadow': 'none' }" @update:show="updateShowHandle">
    <div>
      <div ref="elRef" :class="[base64 ? 'm-auto max-h-80vh overflow-auto scrollbar-default' : 'h-100vh']">
        <div v-if="base64" class="w-80">
          <img :src="base64" alt="bg">
        </div>
      </div>

      <div class="mt-3 select-none">
        <p class="text-white text-3.5 tracking-1 text-center">
          {{ tips ? tips : `${MEDIA_MD ? '右键点击' : '长按'}图片进行保存` }}
        </p>
        <div class="flex items-center justify-center mt-5 cursor-pointer" @click="updateShowHandle(false)">
          <i class="i-solar:close-circle-linear text-white text-7 " />
        </div>
      </div>
    </div>
  </n-modal>
</template>
