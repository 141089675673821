import { validatorPhone } from '@qcwp/utils'
import { authApi } from '~~/src/server'

export type TimeState = 'start' | 'end' | 'load' | 'loading'

export async function getVerification(phone?: string) {
  if (!phone) {
    vantToast('请输入手机号')
    return false
  }
  else if (!validatorPhone(phone)) {
    vantToast('手机号有误')
    return false
  }

  try {
    const { code } = await authApi.getVerification({ phone })
    if (code === 200)
      vantToast('验证码已发送')
  }
  catch (error) {
    vantDialog({
      title: '验证码获取失败',
      message: getErrorMsg(error, '验证码获取失败'),
    })
    return false
  }
  return true
}
