import { rand } from '@qcwp/utils'
import { balloon, cowboy, face, grinning, heart, popper, rose, star } from './icons'

export class LikeAnimation {
  context: CanvasRenderingContext2D
  width: number
  height: number
  canvasScale: number

  FONT_SIZE = 12
  ICON: string[] = [balloon, cowboy, face, grinning, heart, popper, rose, star]

  ICONS: any[] = []
  ICONS_NUM: number = this.ICON.length
  /** 放大阶段（百分比） */
  ENLARGE_STAGE: number
  /** 收缩渐隐阶段（百分比） */
  FADE_OUT_STAGE: number
  // 起始位置
  basicX: number

  currentIndex = 0
  // 是否正在展示动画
  scanning = false
  renderList: { duration: number; timestamp: number; render: (progress: number) => boolean }[] = []

  constructor(canvasId: string, canvasScale: number, config?: { fontSize?: number; icons?: string[] }) {
    const canvas = document.getElementById(canvasId) as HTMLCanvasElement
    this.context = canvas.getContext('2d')!
    this.width = canvas.width
    this.height = canvas.height
    this.canvasScale = canvasScale

    const { fontSize, icons } = config || {}
    this.FONT_SIZE = (fontSize || this.FONT_SIZE) * this.canvasScale
    this.ICON = icons || this.ICON

    this.ENLARGE_STAGE = 0.1
    this.FADE_OUT_STAGE = 0.8
    this.basicX = this.width / 2

    this.loadImage()
  }

  /**
   * 获取横向位移（x轴）
   */
  getTranslateX = (progress: number, frequency: number, amplitude: number) => {
    if (progress < this.ENLARGE_STAGE) {
    // 放大期间，不进行摇摆位移
      return this.basicX
    }
    return this.basicX + amplitude * Math.sin(frequency * (progress - this.ENLARGE_STAGE))
  }

  /**
   * 获取竖向位移（y轴）
   */
  getTranslateY = (progress: number) => {
    return this.FONT_SIZE / 2 + (this.height - this.FONT_SIZE / 2) * (1 - progress)
  }

  /**
   * 获取放缩比例
   */
  getScale = (progress: number) => {
    let r = 1
    if (progress < this.ENLARGE_STAGE) {
    // 放大
      r = progress / this.ENLARGE_STAGE
    }
    else if (progress > this.FADE_OUT_STAGE) {
    // 缩小
      r = (1 - progress) / (1 - this.FADE_OUT_STAGE)
    }
    return r
  }

  /**
   * 获取透明度
   */
  getAlpha = (progress: number) => {
    if (progress < this.FADE_OUT_STAGE)
      return 1
    return 1 - (progress - this.FADE_OUT_STAGE) / (1 - this.FADE_OUT_STAGE)
  }

  /**
   * 预加载图片
   */
  loadImage = () => {
    for (let i = 0; i < this.ICON.length; i++) {
      const p = new Promise((resolve: (image: HTMLImageElement) => void) => {
        const img = new Image()
        img.onerror = () => resolve(img)
        img.onload = () => resolve(img)
        const svg = this.ICON[i]
        img.src = `data:image/svg+xml;base64,${window.btoa(svg)}`
      })
      p.then((img) => {
        if (img && img.width > 0)
          this.ICONS.push(img)
      })
    }
  }

  createRender = () => {
    const index = ++this.currentIndex % this.ICONS_NUM
    // 正弦频率
    const frequency = rand(2, 10)
    // 最大振幅，约束在画布内
    const maxAmplitude = (this.basicX - this.FONT_SIZE / 2) / this.canvasScale
    // 正弦振幅
    const amplitude = rand(0, maxAmplitude) * (rand(0, 1) ? 1 : -1) * this.canvasScale

    return (progress: number) => {
      // 动画过程 0 -> 1
      if (progress >= 1)
        return true

      this.context.save()
      const scale = this.getScale(progress)
      const translateX = this.getTranslateX(progress, frequency, amplitude)
      const translateY = this.getTranslateY(progress)
      this.context.translate(translateX, translateY)
      this.context.scale(scale, scale)
      this.context.globalAlpha = this.getAlpha(progress)
      this.context.drawImage(this.ICONS[index], -this.FONT_SIZE / 2, -this.FONT_SIZE / 2, this.FONT_SIZE, this.FONT_SIZE)
      this.context.restore()
      return false
    }
  }

  createStart = () => {
    const render = this.createRender()
    const duration = rand(2100, 2600)
    if (!render)
      return

    this.renderList.push({ render, duration, timestamp: Date.now() })
  }

  start = () => {
    this.createStart()
    if (!this.scanning) {
      this.scanning = true
      requestAnimationFrame(this.scan)
    }
    return this
  }

  scan = () => {
    this.context.clearRect(0, 0, this.width, this.height)
    let index = 0
    let { length } = this.renderList
    if (length > 0) {
      requestAnimationFrame(this.scan)
      this.scanning = true
    }
    else {
      this.scanning = false
    }
    while (index < length) {
      const child = this.renderList[index]
      if (!child || !child.render || child.render.call(null, (Date.now() - child.timestamp) / child.duration)) {
      // 结束了，删除该动画
        this.renderList.splice(index, 1)
        length--
      }
      else {
        index++
      }
    }
  }
}
