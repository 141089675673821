<script setup lang='ts'>
const props = withDefaults(defineProps<{
  src?: string
  name?: string
  color?: string
}>(), {
  src: '',
  name: '无',
  color: 'bg-blue-500',
})
const avatarLoadError = ref(false)
</script>

<template>
  <div>
    <n-avatar
      v-if="!avatarLoadError"
      v-bind="$attrs"
      object-fit="cover"
      :src="props.src ? props.src : '/noAvatar.jpg'"
      @error="avatarLoadError = true"
    />
    <n-avatar
      v-else
      v-bind="$attrs"
      :color="props.color"
    >
      {{ props.name }}
    </n-avatar>
  </div>
</template>
