<script setup lang='ts'>
import { LOAD_STATUS } from '@qcwp/common'
import { isClient } from '@qcwp/utils'

const props = withDefaults(defineProps<{
  state: LOAD_STATUS
  // 是否自动重新加载，在客户端onMounted时自动加载
  autoReload?: boolean
  effective?: boolean
  emptyText?: string
  errorText?: string
}>(), {
  isEmpty: false,
  autoReload: true,
  emptyText: '暂无相关数据',
  errorText: '数据加载失败',
})
const emits = defineEmits({
  reload: () => true,
})

const canRetry = computed(() => {
  return props.autoReload && RENDER_PLATFORM().value === 'server' && props.state === LOAD_STATUS.ERROR
})
// 仅在服务端渲染且失败是，在客户端自动重新加载
if (canRetry.value && isClient) {
  onMounted(() => {
    emits('reload')
  })
}
</script>

<template>
  <slot v-if="state === LOAD_STATUS.LOADING || canRetry" name="loading">
    <n-skeleton text class="w-full h-4 rounded-md" />
    <n-skeleton text class="w-full h-4 rounded-md mt-3" />
    <n-skeleton text class="w-1/2 h-4 rounded-md mt-3" />
  </slot>
  <slot v-else-if="[LOAD_STATUS.ERROR, LOAD_STATUS.INIT].includes(state)" name="reload">
    <BaseEmpty icon-class="i-custom-undraw-firmware?bg text-30" :text="errorText" m-auto />
    <button class="px-lg py-1.5 rounded-md text-white bg-blue-5 block m-auto" @click="emits('reload')">
      重新加载
    </button>
  </slot>
  <slot v-else-if="[LOAD_STATUS.END].includes(state) && effective" name="default" />
  <slot v-else name="empty">
    <base-empty m-auto :text="emptyText" />
  </slot>
</template>
