<script setup lang='ts'>
function scrollTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}
const { y } = useScroll(window)
</script>

<template>
  <div
    class="bg-white shadow-lg border-1 border-gray-1 rounded-full grid place-content-center w-10 h-10 cursor-pointer"
    :class="[y > 100 ? 'grid' : 'hidden']"
    md="w-15 h-15 text-6"
    title="回到顶部"
    @click="scrollTop"
  >
    <i class="i-carbon:arrow-up" />
  </div>
</template>
