<!--
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2023-10-17 09:20:14
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-05-07 14:37:15
 * @FilePath: \qcwp-vue\packages\web\src\components\utils\quick-tools\menu.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<script setup lang='ts'>
import {
  carHomePath,
  dailyPath,
  loginPath,
  newsDetailPath,
  newsTopicPath,
  reputationHomePath, userPath,
} from '@qcwp/common'
import { useAuth } from '~~/src/store'
const authStore = useAuth()
const { IS_LOGIN } = storeToRefs(authStore)
const isMounted = ref(false)
onMounted(() => isMounted.value = true)
const userUrl = computed(() => (IS_LOGIN.value || !isMounted) ? userPath() : loginPath({ redirect: userPath() }))
</script>

<template>
  <div class="grid grid-cols-6">
    <NuxtLink :to="dailyPath()" class="tool">
      <i i-ic:round-menu-book text-5 />
      <h4>精华</h4>
    </NuxtLink>
    <NuxtLink :to="newsTopicPath()" class="tool">
      <i i-icon-park-solid:topic />
      <h4>专题</h4>
    </NuxtLink>
    <NuxtLink :to="carHomePath()" class="tool">
      <i i-fluent:vehicle-car-profile-ltr-16-filled />
      <h4>车型</h4>
    </NuxtLink>
    <NuxtLink :to="reputationHomePath()" class="tool">
      <i i-mdi:comment-quote text-5 />
      <h4>口碑</h4>
    </NuxtLink>
    <NuxtLink :to="userUrl" class="tool">
      <i i-icon-park-solid:adobe-indesign />
      <h4 class="text-nowrap">
        申请网评号
      </h4>
    </NuxtLink>

    <NuxtLink :to="newsDetailPath('855049')" class="flex ml-1 justify-center w-full h-full text-center items-center">
      <span class="i-custom-zhaopin?bg text-8  cursor-pointer" />
    </NuxtLink>
  </div>
</template>

<style lang='scss' scoped>
.tool {
  --uno: text-center pt-2 gap-0.5 flex flex-col  items-center;
  --uno: bg-white rounded-md  transition;

  & > i {
    --uno: text-6 text-primary;
  }

  & > h4 {
    --uno: text-3.5 text-gray-700 pt-1;
  }
}
</style>
