<script setup lang='ts'>
import { isClient } from '@qcwp/utils'
import { LikeAnimation } from './likeAnimation'
const props = withDefaults(defineProps<{
  count?: number
}>(), {
  count: 0,
})
const emits = defineEmits({
  start: (_count: number) => true,
})
const canvasRef = ref<HTMLCanvasElement | null>(null)
let likeAnimation: LikeAnimation | null = null
function createAutoLikeSetTimeout(count: number) {
  if (count > 10)
    count = 10
  for (let i = 0; i < count; i++) {
    setTimeout(() => {
      likeAnimation?.start()
    }, 200 * i)
  }
}

function clickHandle() {
  likeAnimation?.start()
  emits('start', 1)
}

let stops = [
  watch(() => props.count, (nv, ov = 0) => {
    if (nv > ov + 10)
      createAutoLikeSetTimeout(Math.floor((nv - ov) / 10))
  }),
  watch(() => canvasRef.value, (nv) => {
    if (nv)
      likeAnimation = isClient ? new LikeAnimation('like', 3) : null
  }),
]
onUnmounted(() => {
  stops.forEach(v => v?.())
  stops = null!
})
</script>

<template>
  <div class="select-none grid place-items-center rounded-full " @click="clickHandle">
    <div class="relative h-full">
      <i class="i-bxs:like text-shadow-lg" />
      <div class="pointer-events-none select-none absolute bottom-100% right-50% translate-x-50%">
        <canvas id="like" ref="canvasRef" class="z-2" width="80" height="200" />
      </div>
    </div>
  </div>
</template>
