<script setup lang='ts'>
const props = withDefaults(defineProps<{
  modelValue?: number
  count?: number
  activeColor?: string
  voidColor?: string
  padding?: number
  size?: number
}>(), {
  modelValue: 0, // total 100
  count: 5,
  activeColor: '#FF551D',
  voidColor: '#B9C0D3',
  padding: 5,
  size: 18,
},
)

const voidColor = computed(() => props.voidColor)
const activeColor = computed(() => props.activeColor)
const padding = computed(() => `${props.padding}px`)
const size = computed(() => props.size)

const rateRef = ref<HTMLElement | null>(null)
const width = ref('0')
function updateWidth() {
  if (!rateRef.value)
    return
  let currentWidth = 0
  const totalPadding = (props.count - 1) * props.padding
  const totalWidth = rateRef.value.clientWidth

  // 每份的比重
  const equalCount = (10 / props.count) * 10

  const activeCount = Math.floor(props.modelValue / equalCount)
  const remainder = props.modelValue % equalCount

  // padding在总宽
  const paddingTotalWidth = props.padding * activeCount
  // star在总宽
  const starWidth = (totalWidth - totalPadding) / props.count
  const starTotalWidth = starWidth * activeCount + starWidth / equalCount * remainder
  currentWidth = paddingTotalWidth + starTotalWidth
  width.value = `${currentWidth}px`
}
useResizeObserver(rateRef, () => {
  updateWidth()
})
</script>

<template>
  <div
    ref="rateRef"
    class="flex rate-void relative"
    :style="{
      '--void-color': voidColor,
      '--active-color': activeColor,
      '--width': width,
      '--padding': padding,
      '--size': size,
    }"
  >
    <div class="flex">
      <div v-for="v in props.count" :key="v" class="i-bi:star-fill rate-item" />
    </div>
    <div class="flex rate-active">
      <div class="flex">
        <div v-for="v in props.count" :key="v" class="i-bi:star-fill rate-item" />
      </div>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.rate-void,
.rate-active {
  font-size: var(--size);
}

.rate-void {
  --void-color: "#FF551D";
  --active-color: "#B9C0D3";
  --width: "0px";
  --padding: "0px";
  --size: "18px";

  color: var(--void-color);
}

.rate-active {
  position: absolute;
  overflow: hidden;
  color: var(--active-color);
  width: var(--width);
}

.rate-item {
  margin-right: var(--padding);

  &:last-child {
    margin-right: 0;
  }
}
</style>
