import { isClient, isFunction } from '@qcwp/utils'
import { Poster, PosterType, USER_TYPE, useReadingQuantityPoster } from '@qcwp/common'
import type { PosterContext, PosterJson } from '@qcwp/common'

import { downloadImage } from '../proxyImg'
import type { DefaultPosterOptions, SharePosterOptions, SharePosterType } from './types'
import { useLivePoster } from './live'
import LOGO from '/logo.png'

export * from './types'
export * from './live'

export async function useDefaultPoster(options: DefaultPosterOptions) {
  if (!isClient)
    return

  const color = '#374151'
  const BG_POSTER = useAssetImages('2')
  // const LOGO = useAssetImages('logo')
  const qrcode = await generateCode(options.href, { color: { dark: '#98C2A3ff', light: '#ffffff00' } }) || ''

  /**
   * 海报背景
   */
  function posterBackgroundHandle(context: PosterContext): PosterJson {
    return {
      type: PosterType.image,
      src: BG_POSTER,
      x: 0,
      y: 0,
      width: context.width,
      height: context.height,
    }
  }

  function posterContentHandle(context: PosterContext): PosterJson[] {
    const { width, height } = context
    const offsetX = 15
    const offsetY = 15
    const contentWidth = context.width - offsetX
    const contentHeight = height - offsetY * 2
    const contentJson: PosterJson = {
      type: PosterType.rect,
      x: width / 2 - contentWidth / 2,
      y: offsetY,
      width: contentWidth,
      height: contentHeight,
      bgColor: '#ffffff',
      shadowColor: 'rgba(0, 0, 0, 0.4)',
      shadowBlur: 20,
      boxRadius: 10,
    }

    const mediaJson = posterMediaHandle(context, contentJson)
    // const logoJson: PosterJson[] = posterLogoHandle(context, contentJson)
    const qrcodeJson: PosterJson[] = posterQrcodeHandle(context, contentJson)
    const posterTitleJson: PosterJson = posterTitleHandle(context, contentJson)
    const coverJson: PosterJson = posterCoverHandle(context, posterTitleJson)
    return [
      contentJson,
      // ...logoJson,
      ...qrcodeJson,
      posterTitleJson,
      coverJson,
      ...mediaJson,
    ]
  }
  /**
 * media
 */
  function posterMediaHandle(context: PosterContext, contentJson: PosterJson): PosterJson[] {
    const json: PosterJson[] = []
    const text = options.mediaName
    const font = {
      fontSize: 13,
      lineHeight: 1.3,
    } as const

    const isOriginal = options.mediaType === USER_TYPE.SELF
    const mediaLogoSize = 25
    const mediaLogoJson: PosterJson = {
      type: PosterType.image,
      src: options.mediaLogo,
      objectFit: 'cover',
      x: contentJson.x + 15,
      y: contentJson.y + 10,
      width: mediaLogoSize,
      height: mediaLogoSize,
      boxRadius: mediaLogoSize / 2,
    }

    const mediaNameWidth = context.getTextWidth(text, context.font(font))
    const mediaJson: PosterJson = {
      type: PosterType.text,
      text,
      x: options.mediaLogo ? mediaLogoJson.x + mediaLogoJson.width + 5 : contentJson.x + 15,
      y: mediaLogoJson.y + (mediaLogoJson.height) / 2 + 2,
      textBaseline: 'middle',
      width: mediaNameWidth,
      height: font.fontSize * font.lineHeight,
      ...font,
      color: '#6B7280',
    }

    json.push(mediaLogoJson, mediaJson)

    if (isOriginal) {
      const originalJson: PosterJson = {
        type: PosterType.rect,
        x: contentJson.x + contentJson.width - 45,
        y: mediaLogoJson.y + (mediaLogoJson.height) / 2 - 9,
        width: 30,
        height: 18,
        boxRadius: 3,
        bgColor: '#bfdbfe',
      }
      const originalTextJson: PosterJson = {
        type: PosterType.text,
        text: '原创',
        x: originalJson.x + originalJson.width / 2,
        y: originalJson.y + originalJson.height / 2 + 0.5,
        textBaseline: 'middle',
        textAlign: 'center',
        width: originalJson.width,
        height: originalJson.height,
        fontSize: 10,
        color: '#3b82f6',
      }
      json.push(originalJson, originalTextJson)
    }

    if (options.authorName && (options.authorName !== '官方号' || options.sysUserName)) {
      const font = {
        fontSize: 10,
        lineHeight: 1.3,
      }
      const text = options.authorName !== '官方号' ? `作者：${options.authorName}` : `编辑: ${options.sysUserName}`
      const width = context.getTextWidth(text, context.font(font))
      const x = isOriginal
        ? json[json.length - 2].x - width - 10
        : contentJson.x + contentJson.width - width - 15

      const authorJson: PosterJson = {
        type: PosterType.text,
        text,
        x,
        y: mediaLogoJson.y + (mediaLogoJson.height) / 2 + 1,
        textBaseline: 'middle',
        width: 100,
        height: font.fontSize * font.lineHeight,
        ...font,
        color: '#6B7280',
      }
      json.push(authorJson)
    }

    return json
  }
  /**
   * cover
   */
  function posterCoverHandle(context: PosterContext, titleJson: PosterJson): PosterJson {
    const width = titleJson.width
    const coverJson: PosterJson = {
      type: PosterType.image,
      src: options.cover,
      x: titleJson.x,
      y: titleJson.y + titleJson.height + 10,
      width,
      height: width * 0.56,
      objectFit: 'cover',
    }
    return coverJson
  }
  /**
   * title
   */
  function posterTitleHandle(context: PosterContext, contentJson: PosterJson): PosterJson {
    const text = options.title
    const font = {
      fontSize: 18,
      fontWeight: 'bold',
      lineHeight: 1.3,
    } as const
    const titleJson: PosterJson = {
      type: PosterType.textEllipsis,
      text,
      x: contentJson.x + 15,
      y: contentJson.y + 50,
      textBaseline: 'top',
      width: contentJson.width - 30,
      height: font.fontSize * font.lineHeight * 2,
      ...font,
      color,
    }
    return titleJson
  }
  /**
   * logo
   */
  function posterLogoHandle(context: PosterContext, contentJson: PosterJson): PosterJson[] {
    const { width } = context
    const offsetY = contentJson.y + contentJson.height + 20

    const scale = 2
    const text = '提供个性化汽车资讯'
    const fontSize = 12
    const letterSpacing = 2
    const tipsContentWidth = context.getTextWidth(text, context.font({ fontSize }), letterSpacing)
    const logoJson: PosterJson = {
      type: PosterType.image,
      src: LOGO,
      x: (width - 28 * scale - tipsContentWidth) / 2,
      y: offsetY + 5,
      width: 28 * scale,
      height: 13 * scale,
    }
    // logo tips
    const logoTipsJson: PosterJson = {
      type: PosterType.text,
      text,
      x: logoJson.x + logoJson.width + 5,
      y: logoJson.y + logoJson.height / 2 + fontSize / 2,
      letterSpacing,
      width: tipsContentWidth,
      height: fontSize,
      fontSize,
      color,
      shadowColor: 'rgba(0, 0, 0, 0.6)',
      shadowOffsetX: 5,
      shadowOffsetY: 5,
      shadowBlur: 6,
    }

    return [
      logoJson,
      logoTipsJson,
    ]
  }

  function posterQrcodeHandle(context: PosterContext, contentJson: PosterJson) {
    const qrcodeWidth = 80
    const qrcodeHeight = 80

    const text = ['长按图片识别二维码', '进入查看详情']
    const fontSize = 12
    const tipsWidth = context.getTextWidth(text[0], context.font({ fontSize }))
    const qrcodeJson: PosterJson = {
      type: PosterType.image,
      src: qrcode,
      x: contentJson.x + (contentJson.width - qrcodeWidth - tipsWidth) / 2,
      y: contentJson.y + contentJson.height - qrcodeHeight - 10,
      width: qrcodeWidth,
      height: qrcodeHeight,
    }

    const offsetX = qrcodeJson.x + qrcodeWidth
    const offsetY = (qrcodeHeight - (text.length * fontSize + 8)) / 2
    const tipsContentY = contentJson.y + contentJson.height - qrcodeHeight + offsetY
    const qrcodeText: PosterJson = {
      type: PosterType.text,
      text: text[0],
      x: offsetX,
      y: tipsContentY,
      width: tipsWidth,
      height: fontSize,
      color,
      fontSize,
    }
    const qrcodeText1: PosterJson = {
      type: PosterType.text,
      text: text[1],
      x: offsetX,
      y: tipsContentY + fontSize + 8,
      width: tipsWidth,
      height: fontSize,
      color,
      fontSize,
    }
    return [qrcodeJson, qrcodeText, qrcodeText1]
  }

  async function createPoster() {
    const poster = new Poster({ scale: 3, proxy: downloadImage })
    poster.resize({
      width: 280,
      height: 365,
    })
    const contentJson: PosterJson[] = [
      poster.generateDraw(posterBackgroundHandle),
      ...poster.generateDraw(posterContentHandle),
    ]
    // 内容区域
    return await poster.create(contentJson)
  }

  return {
    createPoster,
  }
}

export function usePoster<Type extends SharePosterType>(type: Type) {
  const base64 = ref('')
  const showPoster = ref(false)
  const getFn = (option: SharePosterOptions[Type]) => {
    return ['article', 'video'].includes(type)
      ? Number(option.lookCount || 0) > 10e4
        ? (options: DefaultPosterOptions) => useReadingQuantityPoster(options, downloadImage)
        : useDefaultPoster
      : useLivePoster
  }
  async function createPoster(option: SharePosterOptions[Type]) {
    const posterHandler = getFn(option)
    if (base64.value) {
      showPoster.value = true
      return
    }

    const { createPoster } = await posterHandler({ LOGO, ...option } as any) || {}
    const { setToast, showToast } = useToast(true, 'dialog', { title: '海报生成失败', loading: '海报生成中...', success: '海报已生成' })
    if (!createPoster || !isFunction(createPoster))
      return setToast('海报生成失败', 'fail')

    createPoster?.().then((res) => {
      base64.value = res.toDataURL('image/png')
      showPoster.value = true
    }).catch((error) => {
      setToast('海报生成失败', 'fail')
      console.error('error', error)
    }).finally(() => {
      showToast()
    })
  }
  /**
   * @deprecated
   */
  async function preCreatePoster(option: SharePosterOptions[Type]) {
    const posterHandler = getFn(option)

    const { createPoster } = await posterHandler(option as any) || {}
    if (!createPoster || !isFunction(createPoster))
      return
    createPoster?.().then((res) => {
      base64.value = res.toDataURL('image/png')
    }).catch((error) => {
      console.error('error', error)
    })
  }
  return {
    createPoster,
    preCreatePoster,
    base64,
    showPoster,
  }
}
