<script setup lang='ts'>

</script>

<template>
  <div class="m-auto flex flex-col items-center py-20">
    <i class="i-custom-undraw-firmware?bg text-40" />
    <div class="text-center font-bold text-gray-500">
      <p>系统升级维护中</p>
      <p>由此造成不便，敬请谅解</p>
    </div>
  </div>
</template>
