import { isClient, isFunction } from '@qcwp/utils'

export enum PAGE_STATE {
  INIT = 'INIT',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  ERROR = 'ERROR',
}
export interface PageInitOptions {
  isServer: boolean
  isShowError: boolean
  errorCallback: (error?: any) => void
}
// 渲染平台
export const RENDER_PLATFORM = () => useState('RENDER_PLATFORM', () => isClient ? 'client' : 'server')
// 渲染状态
export const RENDER_LOAD_STATE = () => useState<PAGE_STATE>('RENDER_LOAD_STATE', () => unref(RENDER_PLATFORM()) === 'server' ? PAGE_STATE.LOADED : PAGE_STATE.INIT)
// 渲染上次修改状态相关信息
export const RENDER_STATE_TIME = () => useState<{ changeTime: number;timeoutClear: any }>('RENDER_STATE_TIME', () => ({
  changeTime: 0,
  timeoutClear: undefined,
}))

export function useSetState() {
  const loadState = RENDER_LOAD_STATE()
  const STATE_TIME = RENDER_STATE_TIME()
  function setState(value: PAGE_STATE) {
    const TIME = 200
    const intervalTime = Date.now() - STATE_TIME.value.changeTime
    STATE_TIME.value.changeTime = Date.now()
    // 如果加载完成的时间小于200,则延时修改状态
    if (value === PAGE_STATE.LOADED && intervalTime < TIME) {
      STATE_TIME.value.timeoutClear && clearTimeout(STATE_TIME.value.timeoutClear)
      STATE_TIME.value.timeoutClear = setTimeout(() => {
        loadState.value = value
      }, TIME - intervalTime)
      return
    }

    loadState.value = value
  }

  return {
    setState,
  }
}
export function useLoadHandle(fn: () => Promise<void>, options?: Partial<PageInitOptions>) {
  const platform = RENDER_PLATFORM()
  const loadState = RENDER_LOAD_STATE()
  const { setState } = useSetState()
  // 是否在服务端加载
  const isShowError = options?.isShowError ?? true
  const errorCallback = options?.errorCallback

  /**
   * 页面数据加载
   * 1. 完成状态在runtime-hooks plugin 中修改
   */
  async function loadHandle() {
    if (!fn || !isFunction(fn))
      return

    // 只在客户端时修改
    if (platform.value === 'client') {
      fn().catch(catchHandle)
    }
    else {
      try {
        await fn()
      }
      catch (error) {
        catchHandle(error)
      }
    }
  }

  function catchHandle(error: any) {
    setState(PAGE_STATE.ERROR)
    isFunction(errorCallback) && errorCallback(error)
    isShowError && showDialogError(error)
  }

  return {
    loadState,
    loadHandle,
  }
}

export function useLoadComponentHandle(fn: () => Promise<void>, options?: Partial<PageInitOptions>) {
  const platform = RENDER_PLATFORM()
  // const loadState = RENDER_LOAD_STATE()
  const loadState = ref(platform.value === 'server' ? PAGE_STATE.LOADED : PAGE_STATE.INIT)
  // 是否在服务端加载
  const isServer = options?.isServer ?? true
  const isShowError = options?.isShowError ?? true
  const errorCallback = options?.errorCallback

  async function loadHandle() {
    if (!fn || !isFunction(fn))
      return

    // 只在客户端时修改
    platform.value === 'client' && setState(PAGE_STATE.LOADING)

    if (isClient) {
      fn().then(() => {
        setState(PAGE_STATE.LOADED)
      }).catch(catchHandle)
    }
    else if (isServer) {
      try {
        await fn()
        setState(PAGE_STATE.LOADED)
      }
      catch (error) {
        catchHandle(error)
      }
    }
  }

  function catchHandle(error: any) {
    setState(PAGE_STATE.ERROR)
    isFunction(errorCallback) && errorCallback(error)
    isShowError && showDialogError(error)
  }

  let changeTime = 0
  let timeoutClear: any
  function setState(value: PAGE_STATE) {
    const TIME = 800
    const intervalTime = Date.now() - changeTime
    changeTime = Date.now()
    // 如果加载完成的时间小于200,则延时修改状态
    if (value === PAGE_STATE.LOADED && intervalTime < TIME) {
      timeoutClear && clearTimeout(timeoutClear)
      timeoutClear = setTimeout(() => {
        loadState.value = value
      }, TIME - intervalTime)
      return
    }

    loadState.value = value
  }
  return {
    loadState,
    loadHandle,
  }
}
