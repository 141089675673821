<!-- eslint-disable no-console -->
<!-- eslint-disable @typescript-eslint/ban-ts-comment -->
<script setup lang='ts'>
import { loginPath } from '@qcwp/common'
import { useAuth } from '~~/src/store'
import { feedback } from '~~/src/server/modules/base'
const { isDesktop } = useDevice()
const { USER_TOKEN } = storeToRefs(useAuth())

const active = ref(false)
const formValue = ref<{ content: string; phone: string; images: string[]; linkUrl: string }>({
  content: '',
  phone: '',
  images: [],
  linkUrl: '',
})
const rules = {
  content: [
    {
      required: true,
      message: '请描述您反馈的内容或遇到的问题',
    },
  ],
}
async function validateHandle(el: any) {
  let isOk = false
  let errorContent = false
  await unref(el)?.validate((errors: any) => {
    if (!errors)
      isOk = true
    else
      errorContent = errors
  })
  return {
    isOk,
    errors: errorContent,
  }
}
const formRef = ref()
const loading = ref(false)
async function submit() {
  const { isOk } = await validateHandle(formRef)
  if (!isOk)
    return
  loading.value = true
  try {
    formValue.value.linkUrl = window.location.href
    await feedback(formValue.value)
    vantToast.success('反馈成功')
    active.value = false
  }
  catch (error) {
    vantDialog.alert({
      title: '反馈失败',
      message: getErrorMsg(error) || '反馈失败',
    })
  }
  finally {
    loading.value = false
  }
}

const router = useRouter()
function checkIsLogin() {
  if (!USER_TOKEN.value) {
    vantDialog.confirm({
      title: '提示',
      message: '您还未登录，是否前往登录？未登录状态下将无法获得反馈回复',
    }).then(() => {
      router.push(loginPath({ redirect: window.location.href }))
    }).catch(() => {})
  }
}

function visibleHandler() {
  active.value = true
  try {
  // @ts-expect-error
    console.log(window?.API_PROMISE_MAP.size)
    // @ts-expect-error
    window?.API_PROMISE_MAP?.clear?.()
    // @ts-expect-error
    console.log(window?.API_PROMISE_MAP.size)
  }
  catch (e) {
    console.error('API_PROMISE_MAP clear error', e)
  }
}
let stop = watch(() => active.value, (val) => {
  val && checkIsLogin()
})
onDeactivated(() => {
  active.value = false
})
onUnmounted(() => {
  stop()
  stop = null!
})
</script>

<template>
  <div
    class="bg-white shadow-lg border-1 border-gray-1 rounded-full flex flex-col items-center justify-center w-10 h-10 cursor-pointer"
    md="w-15 h-15 text-4.5"
    @click="visibleHandler"
  >
    <i class="i-carbon:edit" />
    <span class="text-2.5 md:text-3.5">反馈</span>
  </div>
  <n-drawer v-model:show="active" :width="500" height="80vh" :placement="isDesktop ? 'right' : 'bottom'" close-on-esc>
    <n-drawer-content title="用户反馈" closeable>
      <n-form ref="formRef" :model="formValue" :rules="rules">
        <n-form-item label="问题描述" path="content" required>
          <n-input v-model:value="formValue.content" type="textarea" placeholder="请描述您反馈的内容或遇到的问题" />
        </n-form-item>
        <!-- <n-form-item label="联系方式" path="phone">
          <n-input v-model:value="formValue.phone" placeholder="请留下您的电话号码以便工作人员取得联系" />
        </n-form-item> -->
        <n-form-item v-if="USER_TOKEN" label="图片" path="images">
          <LazyLayoutUpload
            v-model="formValue.images"
            list-type="image-card"
          />
        </n-form-item>
      </n-form>
      <template #footer>
        <div class="flex items-center justify-center w-full">
          <n-button class="mr-5 px-10" @click="active = false">
            取消
          </n-button>
          <n-button :loading="loading" class="mr-5 px-10" type="primary" @click="submit">
            提交
          </n-button>
        </div>
      </template>
    </n-drawer-content>
  </n-drawer>
</template>

<style lang='scss' scoped>

</style>
