<!--
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2023-10-17 09:20:14
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-05-07 14:37:23
 * @FilePath: \qcwp-vue\packages\web\src\components\utils\quick-tools\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<script
  setup
  lang='ts'
>
import {
  dailyPath,
  // carDuibiPath,
  // carHomePath,
  dealerFindPath,
  loginPath,
  newsTopicPath,
  newsTypePath,
  userPath,
  newsDetailPath,
} from '@qcwp/common'
import { useAuth } from '~~/src/store'

const authStore = useAuth()
const { IS_LOGIN } = storeToRefs(authStore)
const isMounted = ref(false)
onMounted(() => isMounted.value = true)
const userUrl = computed(() => (IS_LOGIN.value || !isMounted) ? userPath() : loginPath({ redirect: userPath() }))
</script>

<template>
  <n-card
    title="快捷入口"
    embedded
    rounded-md
    size="small"
    header-style="--n-title-font-weight: bold;"
  >
    <template #header-extra>
      <NuxtLink :to="newsDetailPath('855049')">
        <span class="i-custom-zhaopin?bg text-6  cursor-pointer" />
      </NuxtLink>

      <!-- <span class="border border-red border-solid p-0.5 border-2 color-red rounded cursor-pointer text-3">招聘hot</span> -->
    </template>
    <div
      grid
      grid-cols-3
      gap-2
    >
      <NuxtLink
        :to="dailyPath()"
        class="tool"
      >
        <i
          i-ic:round-menu-book
          text-5
        />
        <h4>精华日报</h4>
      </NuxtLink>
      <NuxtLink
        :to="newsTypePath(12)"
        class="tool"
      >
        <i
          i-fluent:person-call-24-filled
          text-5
        />
        <h4>汽车召回</h4>
      </NuxtLink>
      <NuxtLink
        :to="newsTypePath(8)"
        class="tool"
      >
        <i
          i-fluent:top-speed-20-filled
          text-5
        />
        <h4>评测中心</h4>
      </NuxtLink>
      <NuxtLink
        :to="newsTopicPath()"
        class="tool"
      >
        <i i-icon-park-solid:topic />
        <h4>专题大全</h4>
      </NuxtLink>
      <!-- TODO:next -->
      <NuxtLink
        :to="dealerFindPath()"
        class="tool"
      >
        <i
          i-icon-park-outline:search
          text-5
        />
        <h4>经销商查询</h4>
      </NuxtLink>
      <NuxtLink
        class="tool"
        :to="userUrl"
      >
        <i i-icon-park-solid:adobe-indesign />
        <h4>申请网评号</h4>
      </NuxtLink>
    </div>
  </n-card>
</template>

<style
  lang='scss'
  scoped
>
.tool {
  --uno: text-center py-3 gap-0.5 flex flex-col items-center text-primary cursor-pointer;
  --uno: bg-white rounded-md hover:shadow-lg transition border-1 border-gray-200;

  &>i {
    --uno: text-5;
  }
}
</style>
