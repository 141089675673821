import { catchUploadBase64 } from '~~/src/server/modules/base'
export function downloadImage(src: string) {
  if (!src)
    return Promise.reject(new Error('图片地址不存在'))
  return new Promise<string>((resolve, reject) => {
    catchUploadBase64(src).then((res) => {
      if (res && res.data)
        resolve(res.data)
      else
        reject(new Error('图片流异常'))
    }).catch((error) => {
      reject(error)
    })
  })
}
