<script setup lang='ts'>

</script>

<template>
  <div>
    <n-popover trigger="hover" display-directive="show" raw>
      <template #trigger>
        <div class="flex flex-col items-center cursor-pointer text-gray-400">
          <i class="i-ri:mini-program-line text-4 mb-0.5" />
          <span text-3>小程序</span>
        </div>
      </template>
      <div class="p-3 bg-white flex flex-col gap-0.5 items-center">
        <span text-3 mb-2>扫码访问小程序</span>
        <img class="w-30 h-30" src="/miniprogram-qrcode.jpg" alt="小程序二维码">
      </div>
    </n-popover>
  </div>
</template>
